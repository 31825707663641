<template>
    <v-container>
      <v-layout align-center justify-center>
        <v-flex xs12 sm 12 md6>
          <v-row justify="center">
            <h1>RUN AWAY WITH ME</h1>
          </v-row>
          <v-row justify="center">
            <h4>A Choose Your Own Adventure Game</h4>
          </v-row>
          <v-row justify="center">
    <h2>{{$store.state.current_contributor}}</h2>
          </v-row>
          <v-row justify="center">
    <youtube
      :video-id="$store.state.video_id"
      :player-vars="player_vars"
      @ended="videoEnded"
      :id="$store.state.video_id"
      ref="youtube"
    >
    </youtube>
          </v-row>
          <v-row justify="center">
              <div>
                <h4>Choose Your Path:</h4>
              </div>
          </v-row>
          <v-row justify="center">
              <v-btn dark color="blue" large v-for="(choice, i) in $store.state.next_choices" :key="i" v-on:click="choosePath($event, i)">{{choice.title}}</v-btn>
          </v-row>
          <v-row justify="center">
    <h1>Next Video: {{$store.state.next_vid_name}}</h1>
          </v-row>
          <v-row justify="center">
            <div class="text-center">
    <h5>Press a button to choose which path you want to take when the current video ends.</h5>
    <h5>Your choice will automatically play when the current video ends.</h5>
            </div>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>
export default {
  name: 'Player',
  data () {
    return {
      // video_id: this.$store.state.video_id,
      player_vars: {
        autoplay: 1
      }
    }
  },
  methods: {
    videoEnded: function (event) {
      console.log('ENDED!')
      this.player.pauseVideo()
      this.$store.dispatch('nextVideo').then(() => {
        this.player.playVideo()
      })
    },
    choosePath: function (event, i) {
      this.$store.commit('setNextChoiceInfo', i)
      // this.$store.commit('setNextChoiceIndex', i)
      // this.$store.commit('setNextVidName', this.$store.state.next_choices[i].title)
    }
  },
  mounted () {
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
button {
  margin-left: 20px;
  margin-right: 20px;
}
</style>
