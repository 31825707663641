/* eslint-disable camelcase */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import { random } from 'core-js/fn/number'

Vue.use(Vuex, axios)

export default new Vuex.Store({
  state: {
    video_matrix: {},
    cyoa_path: [],
    title_path: [],
    video_info: {},
    video_id: 'dMdFAmN43UY',
    current_section: 0,
    column_prefix: 'section',
    current_contributor: '',
    next_choices: [],
    next_choice_index: 0,
    next_vid_name: 'Stubbs',
    ignore_gdoc_fields: ['gsx$sectiontitle'],
    ready: false,
    history: [],
    history_titles: []
  },
  mutations: {
    setVideoId (state, payload) {
      state.video_id = payload
    },
    setVideoMatrix (state, payload) {
      state.video_matrix = payload
      this.commit('createPathStructure', payload)
    },
    createPathStructure (state, payload) {
      // this is expecting the usual Google Sheets JSON object used for the video matrix
      const firstRow = payload[0]
      const sections = Object.keys(firstRow).filter(ele => ele.startsWith('gsx$section')).filter(ele => !state.ignore_gdoc_fields.includes(ele))
      // console.log(sections.sort())
      sections.sort().reverse()
      // console.log(sections[0])

      const maxSections = Number(sections[0].replace(/\D/g, ''))
      // console.log(maxSections)

      const tempMatrix = new Array(maxSections + 2)
      const titleMatrix = new Array(maxSections + 2)
      const video_info = {}

      for (let z = 0; z < tempMatrix.length; z++) {
        tempMatrix[z] = []
        titleMatrix[z] = []
      }

      payload.forEach(ele => {
        for (let i = 1; i < maxSections + 1; i++) {
          if (ele['gsx$section' + i].$t) {
            tempMatrix[i].push(ele['gsx$section' + i].$t)
            video_info[ele['gsx$section' + i].$t] = ele.gsx$sectiontitle.$t
            titleMatrix[i].push(ele.gsx$sectiontitle.$t)
          }
        }
      })

      tempMatrix.shift()
      titleMatrix.shift()
      // console.log(tempMatrix)
      const next_choices = []
      tempMatrix[1].forEach(ele => {
        next_choices.push(
          {
            video_id: ele,
            title: video_info[ele]
          }
        )
      })

      state.cyoa_path = tempMatrix
      state.title_path = titleMatrix
      state.video_info = video_info
      state.next_choices = next_choices
      state.ready = true
      state.next_vid_name = next_choices[0].title
      state.current_section = 0
      state.history.push(0)
      state.history_titles.push('Adam At Home')
    },
    setCurrentSection (state, payload) {
      state.current_section = payload
    },
    setCurrentContributor (state, payload) {
      state.current_contributor = payload
    },
    setNextChoices (state, payload) {
      state.next_choices = payload
    },
    setNextChoiceIndex (state, payload) {
      state.next_choice_index = payload
    },
    setNextVidName (state, payload) {
      state.next_vid_name = payload
    },
    setNextChoiceInfo (state, payload) {
      state.next_choice_index = payload
      state.next_vid_name = state.next_choices[payload].title
    },
    appendToHistory (state, payload) {
      state.history.push(payload)
      // console.log(this.state.title_path[this.state.current_section + 1])
      state.history_titles.push(this.state.title_path[this.state.current_section + 1][payload])
    },
    setReady (state, payload) {
      state.ready = true
    }
  },
  actions: {
    getVideoMatrix (state) {
      // console.log("AXIOS IS FIRING")
      axios
        .get('https://spreadsheets.google.com/feeds/list/1wOAKQqZnyCwY_ce2Hki7GLlK9bKDr_PfSPGDcoiflRM/1/public/values?alt=json')
        .then(response => (state.commit('createPathStructure', response.data.feed.entry)))
        // .then(() => state.dispatch('nextVid'))
    },
    nextVid (state) {
      // console.log('NEW VIDEO GO')
      // console.log(`setVideoId: state.current_section: ${this.state.current_section}, state.next_choice_index: ${this.state.next_choice_index}`)
      // console.log(this.state.cyoa_path)
      state.commit('appendToHistory', this.state.next_choice_index)
      state.commit('setVideoId', this.state.cyoa_path[this.state.current_section + 1][this.state.next_choice_index])

      const next_choices = []
      if (this.state.current_section + 2 < this.state.cyoa_path.length) {
        this.state.cyoa_path[this.state.current_section + 2].forEach(ele => {
          next_choices.push(
            {
              video_id: ele,
              title: this.state.video_info[ele]
            }
          )
        })
      } else {
        // do nothing
      }

      state.commit('setNextChoiceIndex', 0)
      state.commit('setNextChoices', next_choices)
      state.commit('setCurrentSection', this.state.current_section + 1)
    },
    nextVideo (state) {
      console.log('NEXT VIDEO IS FIRING')
      state.commit('setCurrentSection', this.state.current_section + 1)
      const random_video_array = this.state.video_matrix[this.state.current_section]
      // console.log(random_video_array)
      const new_video_key = 'gsx$' + this.state.column_prefix + this.state.current_section
      // console.log(next_video_key)

      // now do the things for the next section

      state.commit('setNextChoiceIndex', 0)

      const next_section = this.state.current_section + 1
      const next_video_key = 'gsx$' + this.state.column_prefix + next_section

      const video_choices = []

      this.state.video_matrix.forEach(ele => {
        // console.log(ele[next_video_key]['$t'])

        if (next_video_key in ele) {
          if (ele[next_video_key].$t) {
            video_choices.push(
              {
                video_id: ele[next_video_key].$t,
                title: ele.gsx$sectiontitle.$t
              }
            )
          }
        }
      })

      state.commit('setNextChoices', video_choices)
      state.commit('setCurrentContributor', random_video_array.gsx$sectiontitle.$t)
      state.commit('setVideoId', random_video_array[new_video_key].$t)
      // console.log(random_video_array[next_video_key].$t)
    }
  },
  modules: {
  }
})
