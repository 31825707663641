<template>
  <v-app>
    <v-main>
      <v-container>
      <v-layout align-center justify-center>
        <v-flex xs12 sm 12 md6>
          <v-row justify="center">
            <h1>RUN AWAY WITH ME</h1>
          </v-row>
          <v-row justify="center" class="pb-3">
            <h4>A Choose Your Own Adventure Musical Experience</h4>
          </v-row>
          <v-row justify="center">
    <h2>{{$store.state.current_contributor}}</h2>
          </v-row>
          <v-row justify="center">
    <youtube
      :video-id="video"
      :player-vars="player_vars"
      @ended="videoEnded"
      :id="video"
      ref="youtube"
    >
    </youtube>
          </v-row>
          <v-row justify="center">
            <h1>{{title}}</h1>
          </v-row>
          <v-row justify="center" class="pb-2">
              <div>
                <!-- <h4>Choose Your Path</h4> -->
              </div>
          </v-row>
          <v-row justify="center">
              <v-btn class="tombtn" dark :color="getColor(i)" large v-for="(choice, i) in $store.state.next_choices" :key="i" v-on:click="choosePath($event, i)">{{choice.title}}</v-btn>
          </v-row>
          <v-row justify="center">
    <!-- <h4>Next Video: {{next_title}}</h4> -->
          </v-row>
          <v-row justify="center" class="py-3">
            <div class="text-center">
    <h5>Press a button to choose which path you want to take when the current video ends.</h5>
    <h5>Your choice will automatically play when the current video ends.</h5>
            </div>
          </v-row>
          <v-row justify="center" class="py-3">
            <v-col class="text-center">
              <h6>{{ roadtrip }}</h6>
            </v-col>
          </v-row>
          <!-- <v-row justify="center" align="center">
            <v-col v-for="(section, i) in $store.state.title_path" :key="i" align="center" justify="center" class="pa-0 flex-column">
              <v-chip x-small v-for="(t, x) in section" :key="x">{{ t }}</v-chip>
            </v-col>
          </v-row> -->
        </v-flex>
      </v-layout>
    </v-container>
    </v-main>
    <v-bottom-navigation
      class="elevation-0"
    >
        <span class="smol pt-5"><a href="https://libretto.tech/">App made with love by Libretto Interactive</a></span>
    </v-bottom-navigation>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },
  mounted () {
    console.log('🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖')
    console.log('🦖     HELLO, JEREMY JORDAN     🦖')
    console.log('🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖🦖')
    this.$store.dispatch('getVideoMatrix').then(() => {
      // this.$store.dispatch('nextVid').then(() => {
      //   this.player.playVideo()
      // })
      // this.$store.commit('setNextChoiceInfo', 0)
    })
  },
  // async mounted () {
  //   await this.$store.dispatch('getVideoMatrix')
  //   await this.$store.dispatch('nextVid')
  //   this.player.playVideo()
  // },
  data: () => ({
    player_vars: {
      autoplay: 1
    }
  }),
  methods: {
    videoEnded: function (event) {
      console.log('ENDED!')
      this.player.pauseVideo()
      if (this.$store.state.current_section === this.$store.state.cyoa_path.length - 2) {
        // pause!
      } else {
        this.$store.dispatch('nextVid').then(() => {
          this.player.playVideo()
        // this.$store.commit('setNextChoiceInfo', 0)
        })
      }
    },
    choosePath: function (event, i) {
      this.$store.commit('setNextChoiceInfo', i)
      // this.$store.commit('setNextChoiceIndex', i)
      // this.$store.commit('setNextVidName', this.$store.state.next_choices[i].title)
    },
    getColor: function (i) {
      return i === this.$store.state.next_choice_index ? 'purple' : 'grey'
    }
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    },
    video () {
      return this.$store.state.video_id
    },
    next_title () {
      if (this.$store.state.next_choices.length > 0) {
        return this.$store.state.next_choices[this.$store.state.next_choice_index].title
      } else {
        return ''
      }
    },
    title () {
      return this.$store.state.video_info[this.$store.state.video_id]
    },
    tom () {
      return this.$store.state.history.join('-')
    },
    roadtrip () {
      return this.$store.state.history_titles.join(' -> ')
    }
  }
}
</script>

<style>
/* @font-face {
  font-family: "Mononten";
  src: url("/Mononten.otf");
}
@font-face {
  font-family: "Devant Horgen";
  src: url("/Devant_Horgen.otf");
} */

.v-application {
  font-family: 'Mononten', monospace !important;
}
.tombtn {
  margin-left: 20px;
  margin-right: 20px;
}
.smol {
  color: #aaa;
  font-size: 0.6em;
  text-decoration: none !important;
}

.smol > a {
  color: #aaa !important;
  text-decoration: none !important;
}

html {
  font-size: 20px !important;
}
</style>
